.Event {
  display: flex;
  margin: 2px 10px;
  padding: 3px;
  font-size: 15px;
  align-items: center;
  transition: 150ms ease-out;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
}

.Event:hover {
  border: 1px solid #dadce0;
}

.Creator {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Bullet {
  width: 11px;
  height: 11px;
  border-radius: 20px;
  border: 3px solid;
  margin-right: 10px;
  flex-shrink: 0;
}

.Indefinite {
  background-color: transparent !important;
}

@media(max-width: 700px){
  .Creator{
    display: none;
  }

  .Bullet{
    margin-right: 0;
  }

  .Event{
    justify-content: space-around;
  }
}