.FormRow:not(:last-of-type) {
  margin-bottom: 20px;
}

.FormRow > div {
  width: 100%;
}

.Title {
  margin: 0 0 5px 0;
  font-size: 24px;
  font-family: "Roboto";
  color: #3c4043;
  font-weight: normal;
  text-align: left;
}

.Author {
  margin-top: -10px;
  font-weight: lighter;
}

.FormRowFlex {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.FormRowFlex > div {
  width: 50%;
}

.Form {
  text-align: left;
}

.Buttons {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.Button {
  width: calc(50% - 10px);
}

.Button:last-of-type {
  margin-left: 20px;
}
