.ColorPicker{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    position: relative;
    right: 10px;
}

.ColorPicker > div{
    cursor: pointer;
    width: 25px;
    height: 25px;
    border: 5px solid;
    border-radius: 50%;
    margin: 5px 10px;
}

.Active{
    background-color: transparent !important;
}