.Calendar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Weekdays{
  display: flex;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  background-color: #fff;
  position: relative;
  z-index: 5;
  padding-left: 22px;
}

.Weekdays div{
  border-right: 1px solid #dadce0;
  color: #6f7479;
  font-size: 13px;
  padding: 2px 0;
  text-transform: uppercase;
  font-family: 'roboto';
  text-align: center;
  width: calc((100% / 7) - 1px);
}