.FormRow:not(:last-of-type) {
  margin-bottom: 20px;
}

.FormRow > div {
  width: 100%;
}

.Title {
  margin: 0 0 20px 0;
  font-size: 24px;
  font-family: "Roboto";
  color: #3c4043;
  font-weight: normal;
  text-align: left;
}

.Author {
  text-align: left;
  margin-top: -10px;
  font-weight: lighter;
}

.FormRowFlex {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.FormRowFlex > div {
  width: 50%;
}

.Form {
  text-align: left;
  position: relative;
}

.Form::before {
  content: " ";
  display: table;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  transition: 150ms ease-out;
}

.Form::after {
  content: " ";
  display: table;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 6px solid #fff;
  border-top-color: transparent;
  animation: rotate 500ms ease-out infinite;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: 150ms ease-out;
}

.Loading::before,
.Loading::after {
  opacity: 1;
}

.Buttons {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.Button {
  width: calc(50% - 10px);
}

.Button:last-of-type {
  margin-left: 20px;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
