.Modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 50;
}

.Inner {
  width: calc(100% - 40px);
  transform: translateY(-20px);
  opacity: 0.001;
  max-width: 320px;
  margin: 40px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  transition: 200ms ease-out;
}

.Active {
  opacity: 1;
  transform: translateY(0px);
}

.Close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 10px;
  cursor: pointer;
}

.Close::after,
.Close::before {
  position: absolute;
  content: " ";
  display: table;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background-color: #444;
  transform: translate(-50%, -50%) rotate(45deg)
}

.Close::before{
  transform: translate(-50%, -50%) rotate(-45deg)
}
