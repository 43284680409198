.MonthView {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  width: calc(100% - 22px);
}

.Container {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.Week {
  display: flex;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid #dadce0;
}

.Week > .Day {
  border-right: 1px solid #dadce0;
  width: calc((100% / 7) - 1px);
  transition: 100ms ease-out;
  cursor: pointer;
}

.Day:hover {
  background-color: #f9f9f9;
}

.Week > div {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.DayLabel {
  font-size: 13px;
}

.CurrentDayLabel {
  border-radius: 50%;
  padding: 3px;
  background-color: #1972e8;
  color: #fff;
}

.Previous,
.Next {
  font-style: italic;
  opacity: 0.75;
}

.SwipeRight {
  animation: swipe-right 200ms ease-out;
}

.SwipeLeft {
  animation: swipe-left 200ms ease-out;
}

.Loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-out;
  z-index: 10;
}

.Loading {
  opacity: 1;
  pointer-events: all;
}

.Spinner {
  content: " ";
  display: table;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 6px solid #fff;
  border-top-color: transparent;
  animation: rotate 500ms ease-out infinite;
  z-index: 100;
}

.BackendHint {
  width: 300px;
  border-radius: 7px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 12px;
  left: 50%;
  top: 50px;
  transform: translate(-50%, -50px);
  opacity: 0;
  transition: 500ms ease-out;
}

.LoadingLong .BackendHint {
  opacity: 1;
  transform: translate(-50%, 0px);
}

.CalendarWeeks {
  display: flex;
  flex-direction: column;
}

.CalendarWeeks div {
  flex-grow: 1;
  font-size: 12px;
  padding: 5px 0;
  width: 22px;
  border-bottom: 1px solid #dadce0;
  background-color: #f1f3f4;
  color: #3c4043;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes swipe-right {
  50% {
    transform: translateX(-50px);
    opacity: 0;
  }

  51% {
    transform: translateX(50px);
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes swipe-left {
  50% {
    transform: translateX(50px);
    opacity: 0;
  }

  51% {
    transform: translateX(-50px);
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
