.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  z-index: -5;
}

.Login {
  margin: 100px auto 30px auto;
  width: calc(100% - 60px);
  max-width: 520px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.Error {
  color: #d32f2f;
}

.Title {
  font-family: "Roboto";
  font-weight: normal;
  margin: 5px 0 10px 0;
}

.Wide {
  width: 100%;
}

.Loading {
  position: relative;
}

.Loading::after {
  content: " ";
  display: table;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-top-color: transparent;
  animation: rotate 500ms ease-out infinite;
}

.Hint {
  margin: 0px auto 30px auto;
  width: calc(100% - 60px);
  max-width: 520px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  opacity: 0;
  transition: 200ms ease-out;
  transform: translateY(-20px);
}

.Hint p {
  margin: 0;
}

.ShowHint {
  opacity: 1;
  transform: translateY(0);
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (max-width: 750px) {
  .Login {
    margin: 30px auto;
  }
}
