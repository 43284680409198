.Controls {
  color: #3c4043;
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: #fff;
  position: relative;
  z-index: 5;
}

.Controls p {
  margin: 0;
}

.Logo {
  font-family: "Roboto";
  font-size: 24px;
  display: flex;
  align-items: center;
}

.Logo span {
  font-size: 40px;
  margin-right: 15px;
}

.Today {
  cursor: pointer;
  border: 1px solid #dadce0;
  padding: 8px 13px;
  border-radius: 5px;
}

.Arrow {
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: 200ms ease-out;
  margin-left: 20px !important;
}

.Arrow:hover {
  background-color: #eee;
}

.Arrow::after {
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  content: " ";
  width: 10px;
  height: 10px;
  border-top: 3px solid #999;
  border-right: 3px solid #999;
}

.Next::after {
  transform: translate(-75%, -50%) rotate(45deg);
}

.Prev::after {
  transform: translate(-25%, -50%) rotate(-135deg);
}

.SwitchControls {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.MonthLabel {
  text-align: left;
  margin-left: 20px !important;
  font-size: 24px;
  margin: 0 20px;
  flex-grow: 1;
}

.Logout {
  text-align: right;
  margin-right: 20px !important;
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: 809px) {
  .MonthLabel,
  .Logo {
    font-size: 20px;
  }

  .Logo span {
    font-size: 32px;
  }

  .Logout {
    font-size: 16px;
  }
}

@media (max-width: 720px) {
  .Logo p {
    display: none;
  }

  .SwitchControls {
    margin-left: 10px;
  }

  .Arrow {
    margin-left: 10px !important;
  }
}

@media (max-width: 540px) {
  .Logo {
    display: none;
  }

  .SwitchControls {
    margin-left: 0px;
  }

  .Today {
    padding: 8px;
    font-size: 14px;
  }

  .MonthLabel {
    font-size: 17px;
  }

  .Logout {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .Logout {
    margin-right: 0 !important;
  }

  .Arrow {
    margin-left: 5px !important;
  }

  .MonthLabel {
    margin-left: 10px !important;
    font-size: 15px;
  }
}
